import React, { useEffect } from 'react';
import { URLS } from '../../regions';
import drug from '../../images/drug.png';
import health from '../../images/health.png';
import carrierstatus from '../../images/carrierstatus.png';
import healthheart from '../../images/healthheart.png';
import wellness from '../../images/wellness.png';
import relatives from '../../images/relatives.png';
import iconmoretolearn from '../../images/iconmoretolearn.png';
import historicalmatches from '../../images/historicalmatches.png';
import records from '../../images/records.png';
import CaretRight from '../Svgs/CaretRight';
import SubscriptionName from './SubscriptionName';
import SubscriptionInfoValueProp from './SubscriptionInfoValueProp';
import { useStore } from '../../utils/storeUtils';
import dataLayer from '../../utils/dataLayer';

const UpgradeSubscriptionInfo = () => {
  const [storeState, _] = useStore();
  const { region } = storeState;
  const upgradeValueProps = (
    <ul className="spc-subs-info-ul">
      <SubscriptionInfoValueProp
        title="Health Predisposition reports*"
        description="Learn about genetic factors that may influence your chances of developing certain
            health conditions, such as type 2 diabetes and celiac disease."
        icon={healthheart}
        iconLabel="health predispositions"
      />
      <SubscriptionInfoValueProp
        title="Carrier Status reports*"
        description="Find out if you carry certain genetic variants linked to certain inheritable
            conditions, such as cystic fibrosis and sickle cell anemia, that you may pass on to
            your future children."
        icon={carrierstatus}
        iconLabel="carrier status"
      />
      <SubscriptionInfoValueProp
        title="Wellness reports"
        description="Find out how your DNA can help you make more informed lifestyle choices related to
            diet, exercise, and more."
        icon={wellness}
        iconLabel="wellness"
      />
    </ul>
  );
  useEffect(() => {
    if (storeState.showRecordsArchives) dataLayer.trackFamilyRecordsUpgradeSubscriptionPageView();
  }, []);

  const membershipValueProps = (
    <ul className="spc-subs-info-ul">
      {storeState.showRecordsArchives && (
        <SubscriptionInfoValueProp
          title="Records & Archives"
          description="Explore historical records about your ancestors and 
              choose information to add directly to your family tree. "
          icon={records}
          iconLabel="historical records and archives"
          region={region}
        />
      )}
      <SubscriptionInfoValueProp
        title="Heart Health reports"
        description="Get a better picture of what may be in store for your heart's health and learn about
            lifestyle factors and steps that can help you stay healthy."
        icon={health}
        iconLabel="heart health reports"
      />
      {storeState.showHistoricalMatches && (
        <SubscriptionInfoValueProp
          title="Historical Matches"
          description="Uncover the DNA you share with people who lived hundreds or thousands of years ago.
              More historical profiles added throughout the year."
          icon={historicalmatches}
          iconLabel="historical matches"
          region={region}
        />
      )}
      <SubscriptionInfoValueProp
        title="Pharmacogenetics reports**"
        description="Discover how your DNA may impact the way your body processes certain medications with
            three Pharmacogenetics reports plus Medication Insights on citalopram and clopidogrel. Share
            these reports with your doctor to make smarter decisions together."
        icon={drug}
        iconLabel="pharmacogenetics"
      />
      <SubscriptionInfoValueProp
        title="Advanced DNA Relatives features"
        description="Gain access to up to 5,000 DNA relatives – more than three times what you get in the
            Health + Ancestry Service."
        icon={relatives}
        iconLabel="dna relatives features"
      />
      <SubscriptionInfoValueProp
        title="More to learn"
        description="New premium reports and features delivered to you throughout the year to keep fueling
            your health journey."
        icon={iconmoretolearn}
        iconLabel="more to learn"
      />
    </ul>
  );

  const valueProps = (
    <>
      <div className="spc-subs-info-subtitle">
        <div className="subtitle-text">
          Included with <SubscriptionName useServiceMark={true} iconSize={10} />:
        </div>
      </div>
      {membershipValueProps}
      <div className="spc-subs-info-subtitle">
        <div className="subtitle-text">Included with the Health + Ancestry Service upgrade:</div>
      </div>
      {upgradeValueProps}
      <a
        href={URLS.PGT_PERMALINK}
        rel="noopener noreferrer"
        target="_blank"
        className="spc-subs-info-test-info mod-hide-for-mobile mod-upgrade"
        data-mdv-id="subscription-payment-test-info-link"
      >
        Important Test Info
        <span>
          <CaretRight className="caret-right" height="24" width="24" />
        </span>
      </a>
    </>
  );

  return (
    <div className="spc-subs-info mod-upgrade">
      <div className="spc-subs-info-title">
        <h1 className="spc-subs-info-h1" data-mdv-id="subscription-payment-header">
          Do more with your DNA
        </h1>
      </div>
      <div className="spc-subs-info-desktop">{valueProps}</div>
    </div>
  );
};

export default UpgradeSubscriptionInfo;
