import { useEffect } from 'react';
import { useStore } from '../../../../utils/storeUtils';
import GaEventHandler from '../../../../utils/dataLayer';

export const useGoogleTracking = (subscriptionInfo: SubscriptionInfo) => {
  const [storeState] = useStore();

  useEffect(() => {
    if (!subscriptionInfo) return;
    const source = storeState.upsellSource || 'unknown';
    let eventCategory;
    switch (true) {
      case subscriptionInfo.isHealthUpgrade === true:
        eventCategory = 'store_upgrade_subscription_payment_page';
        break;
      case subscriptionInfo.isSubscriptionRenewal === true:
        eventCategory = 'store_subscription_renewal_payment_page';
        break;
      default:
        eventCategory = 'store_subscription_payment_page';
    }

    GaEventHandler.trackSubscriptionPaymentPageView(eventCategory, source);
  }, []);
};
