import { BillingMethods } from '../constantsv2';

interface BaseEcommerceEvent {
  event?: string;
  ecommerce?: Record<string, unknown> | null;
  gaEventCategory?: string;
  gaEventAction?: string;
  gaEventLabel?: string;
  gaEventValue?: number;
  gaEventNonInteractionBool?: boolean;
}
interface ParsedKitItem {
  index: number;
  item_id: string;
  item_name: string;
  currency: string;
  price: number;
  coupon?: string;
  discount: number;
  item_brand: '23andMe';
  item_category: 'DNA Kits';
  item_category2?: string;
  item_category3?: string;
  quantity: 1;
}

type EventCategory =
  | 'store_upgrade_subscription_payment_page'
  | 'store_subscription_payment_page'
  | 'store_subscription_renewal_payment_page';

class GaEventHandler {
  static GTM_EVENT_ADD_SHIPPING_INFO = 'add_shipping_info';
  static GTM_EVENT_ADD_PAYMENT_INFO = 'add_payment_info';
  static GTM_EVENT_UPGRADE_IN_CART = 'upgrade_in_cart';
  static GTM_EVENT_DOWNGRADE_IN_CART = 'downgrade_in_cart';
  static GTM_EVENT_TRACK_TOTAL_HEALTH = 'track_th';
  static GTM_EVENT_TOTAL_HEALTH_UPGRADE = 'checkout_total_health';
  static GTM_EVENT_CHECKOUT_UPGRADE_SUBSCRIPTION = 'checkout_upgrade_subscription';
  static GTM_EVENT_CHECKOUT_SUBSCRIPTION = 'checkout_subscription';

  static pushToDataLayer(eventDetails: BaseEcommerceEvent = { event: '', ecommerce: {} }): void {
    if (!window.dataLayer) {
      return;
    }
    window.dataLayer.push(eventDetails);
  }

  static trackNonEcommerceGaEvent(
    category: string,
    action: string,
    label: string,
    interactive: boolean = false,
  ) {
    // Non ecommerce GA events need to have an event of 'gaEvent' to fire a network call. NonInteractionBool should be true for non-interactive events.
    const payload = {
      event: 'gaEvent',
      gaEventCategory: category,
      gaEventAction: action,
      gaEventLabel: label,
      gaEventValue: 0,
      gaEventNonInteractionBool: !interactive,
    };
    this.trackEvent(payload);
  }

  static parseKit(currency: string, kit: Kit, index: number): ParsedKitItem {
    return {
      index: index,
      item_id: String(kit.itemId),
      item_name: kit.itemName,
      currency: currency,
      price: kit.discountPrice,
      coupon: kit.discountProgram,
      discount: kit.discount,
      item_brand: '23andMe',
      item_category: 'DNA Kits',
      item_category2: kit.itemCategory2,
      item_category3: kit.itemCategory3,
      quantity: 1,
    };
  }

  static parseSubscription(subscriptionInfo: SubscriptionInfo): ParsedKitItem {
    const category2 = {
      US: 'US Health',
      CA: 'CA Health',
      GB: 'UK Health',
      DK: 'EU Health',
      FL: 'EU Health',
      IE: 'EU Health',
      NL: 'EU Health',
      SE: 'EU Health',
    };
    const itemCategory2 = category2.hasOwnProperty(subscriptionInfo.sampleShippingCountry)
      ? category2[subscriptionInfo.sampleShippingCountry]
      : 'US Health';
    const hasDiscount = subscriptionInfo.discountAmountNumber > 0;
    const discountedPrice = hasDiscount
      ? subscriptionInfo.discountedPriceNumber
      : subscriptionInfo.priceNumber;
    return {
      index: 0,
      item_id: 'plan-' + subscriptionInfo.storePlanId,
      item_name: subscriptionInfo.planName,
      currency: subscriptionInfo.currency,
      price: discountedPrice,
      coupon: subscriptionInfo.coupon,
      discount: subscriptionInfo.discountAmountNumber,
      item_brand: '23andMe',
      item_category: 'DNA Kits',
      item_category2: itemCategory2,
      item_category3: 'Sub',
      quantity: 1,
    };
  }

  static trackEvent(payload: BaseEcommerceEvent, gtmNullPayload = false): void {
    window.dataLayer = window.dataLayer || []; // Initialize dataLayer if it doesn't exist.
    if (gtmNullPayload) {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    }
    window.dataLayer.push(payload);
  }

  static addShippingInfo(data: Cart, shipMethod: ShipMethod, gtmNullPayload?: boolean): void {
    const payload = {
      event: this.GTM_EVENT_ADD_SHIPPING_INFO,
      ecommerce: {
        currency: data.currency,
        value: data.totals.itemsTotal,
        shippingTier: shipMethod.code,
        items: data.kits.map((item, index) => this.parseKit(data.currency, item, index)),
      },
    };
    this.trackEvent(payload, gtmNullPayload);
  }

  static paymentMethod(data: SavedPayment): BillingMethods {
    let method: BillingMethods = BillingMethods.CREDIT_CARD;
    if (data.isApplepay) {
      method = BillingMethods.APPLEPAY;
    } else if (data.isPaypalAccount) {
      method = BillingMethods.PAYPAL;
    }
    return method;
  }

  static addPaymentInfo(data: Cart, billing: Billing, gtmNullPayload?: boolean): void {
    let method = billing.method;
    if (method === BillingMethods.SAVED_PAYMENT) {
      // TODO - why are saved payment flags tucked away in customAddress?
      // @ts-ignore
      method = this.paymentMethod(billing.customAddress);
    }
    const payload = {
      event: this.GTM_EVENT_ADD_PAYMENT_INFO,
      ecommerce: {
        currency: data.currency,
        value: data.totals.itemsTotal,
        paymentType: method,
        items: data.kits.map((item, index) => this.parseKit(data.currency, item, index)),
      },
    };
    this.trackEvent(payload, gtmNullPayload);
  }

  static addPaymentInfoSubs(
    subscriptionInfo: SubscriptionInfo,
    savedPayment: SavedPayment,
    kit: Kit,
    gtmNullPayload?: boolean,
  ): void {
    let method = this.paymentMethod(savedPayment);
    let items = [this.parseSubscription(subscriptionInfo)];
    let value = subscriptionInfo.priceNumber;
    if (subscriptionInfo.isHealthUpgrade) {
      items.push(this.parseKit(subscriptionInfo.currency, kit, 1));
      value = value + kit.discountPrice + kit.discount;
    }

    const payload = {
      event: this.GTM_EVENT_ADD_PAYMENT_INFO,
      ecommerce: {
        currency: subscriptionInfo.currency,
        value: value,
        paymentType: method,
        items: items,
      },
    };
    this.trackEvent(payload, gtmNullPayload);
  }

  static trackTotalHealth(identifier: string, totalKits: number, gtmNullPayload?: boolean): void {
    const payload = {
      event: this.GTM_EVENT_TRACK_TOTAL_HEALTH,
      ecommerce: {
        identifier: identifier,
        total_kits: totalKits,
      },
    };
    this.trackEvent(payload, gtmNullPayload);
  }

  static totalHealthAcceptSavePayment(alias: string, price: number): void {
    const payload = {
      event: this.GTM_EVENT_TOTAL_HEALTH_UPGRADE,
      ecommerce: {
        event_category: 'youdot_total_health_checkout',
        event_action: 'box_checked',
        event_label: 'payment_saved',
        alias: alias,
        price: price,
      },
    };
    this.trackEvent(payload);
  }

  static totalHealthAcceptTos(alias: string, price: number): void {
    const payload = {
      event: this.GTM_EVENT_TOTAL_HEALTH_UPGRADE,
      ecommerce: {
        event_category: 'youdot_total_health_checkout',
        event_action: 'box_checked',
        event_label: 'terms_of_service',
        alias: alias,
        price: price,
      },
    };
    this.trackEvent(payload);
  }

  static trackFamilyRecordsSubscriptionInfoPageView(): void {
    this.trackNonEcommerceGaEvent(
      'store_subscription_payment_page',
      'page_view',
      'family_tree_hint',
    );
  }

  static trackSubscriptionPaymentPageView(event_category: EventCategory, source?: string): void {
    source = source ? source : 'unknown';
    this.trackNonEcommerceGaEvent(event_category, 'page_view', source);
  }

  static trackFamilyRecordsUpgradeSubscriptionPageView(): void {
    this.trackNonEcommerceGaEvent(
      'store_upgrade_subscription_payment_page',
      'page_view',
      'family_tree_hint',
    );
  }
}

// Window augmentation for dataLayer
declare global {
  interface Window {
    dataLayer: BaseEcommerceEvent[];
  }
}

export default GaEventHandler;
