import React, { useEffect } from 'react';
import { useStore } from '../utils/storeUtils';
import Header from '../components/Header';
import SubscriptionRenewalTerms from '../components/SubscriptionPayment/SubscriptionRenewalTerms';
import SubscriptionPricing from '../components/SubscriptionPayment/SubscriptionPricing';
import iconPlusCloud from '../images/iconPlusCloud.svg';
import { URLS } from '../regions';
import { sendToNative, EVENTS_TO_MOBILE } from '../utils/nativeMobile';
import GaEventHandler from '../utils/dataLayer';
import Footer from '../components/Footer';
import Survey from './Survey';
import SubscriptionName, {
  injectSubscriptionName,
} from '../components/SubscriptionPayment/SubscriptionName';

export default function SubscriptionReceipt() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storeState, _] = useStore();
  const { receiptId, activatedAt, isTotalHealth } = storeState.subscriptionInfo;
  const logoUrl = storeState.account.isAuthenticated
    ? `${storeState.youdotUrl}`
    : storeState.pubUrl;
  const ctaUrl = storeState.successUrl ? storeState.successUrl : logoUrl;
  const accountEmail = storeState.account.email;

  const isUpgradeBundle = storeState.subscriptionInfo.isHealthUpgrade;
  const isSubBundle = isUpgradeBundle;
  const isGenotyped = storeState.subBundleInfo.isGenotyped;
  const hasValidPaymentInfo =
    storeState.billingInfo.cardType &&
    storeState.billingInfo.expirationMonth &&
    storeState.billingInfo.lastFour;
  const hasValidName = storeState.billingInfo.firstName || storeState.billingInfo.lastName;
  const hasValidAddress = storeState.billingInfo.lineOne || storeState.billingInfo.city;

  const genotypeHash = isGenotyped ? 'LOADED' : 'CLAIMED';
  const serviceHash = isUpgradeBundle ? 'UPGRADE_BUNDLE' : 'SUBSCRIPTION';

  useEffect(() => {
    GaEventHandler.pushToDataLayer({
      event: 'gaEvent',
      gaEventCategory: 'AnalyticsEvent',
      gaEventAction: 'store_receipt_pageview',
      gaEventValue: 0,
      gaEventNonInteractionBool: true,
    });
  }, []);

  interface CopyText {
    HEADER1: string;
    HEADER2: string;
    SUBHEADER: string;
    PURCHASED_FOR: string;
    SETTINGS_TAG: string;
    CTA: string;
  }

  interface GenotypeStatus {
    CLAIMED: CopyText;
    LOADED: CopyText;
  }

  type ServiceHash = 'SUBSCRIPTION' | 'UPGRADE_BUNDLE';
  type Service = Record<ServiceHash, GenotypeStatus>;

  const COPYTEXT: Service = {
    SUBSCRIPTION: {
      CLAIMED: {
        HEADER1: 'You’ve joined 23andMe+',
        HEADER2: '',
        SUBHEADER: 'We sent a purchase confirmation to',
        PURCHASED_FOR: '23andMe+ member',
        SETTINGS_TAG: '',
        CTA: 'Take me back to my account',
      },
      LOADED: {
        HEADER1: 'You’ve joined 23andMe+',
        HEADER2: '',
        SUBHEADER: 'We sent a purchase confirmation to',
        CTA: 'Start exploring 23andMe+ ',
        PURCHASED_FOR: '23andMe+ member',
        SETTINGS_TAG: '',
      },
    },
    UPGRADE_BUNDLE: {
      CLAIMED: {
        HEADER1: 'You’ve purchased an upgrade to the Health + Ancestry Service',
        HEADER2: 'and a membership to 23andMe+',
        SUBHEADER: 'We sent purchase confirmations for the upgrade and the 23andMe+ membership to',
        PURCHASED_FOR: 'Upgrade and 23andMe+ purchased for',
        SETTINGS_TAG:
          'If you cancel your membership, you will lose access to membership features only; you will not lose access to the Health + Ancestry Service.',
        CTA: 'Take me back to my account',
      },
      LOADED: {
        HEADER1: 'You’ve upgraded to the Health + Ancestry Service',
        HEADER2: 'and joined 23andMe+',
        SUBHEADER: 'We sent purchase confirmations for the upgrade and the 23andMe+ membership to',
        CTA: 'Start exploring your new reports',
        PURCHASED_FOR: 'Upgrade and 23andMe+ purchased for',
        SETTINGS_TAG:
          'If you cancel your membership, you will lose access to membership features only; you will not lose access to the Health + Ancestry Service.',
      },
    },
  };

  const params = { iconSize: 19, useIconOffset: true, useServiceMark: true, isTotalHealth };
  const header1 = injectSubscriptionName(COPYTEXT[serviceHash][genotypeHash]['HEADER1'], params);
  const header2 = injectSubscriptionName(COPYTEXT[serviceHash][genotypeHash]['HEADER2'], params);
  const subheader = injectSubscriptionName(COPYTEXT[serviceHash][genotypeHash]['SUBHEADER']);
  const cta = injectSubscriptionName(COPYTEXT[serviceHash][genotypeHash]['CTA'], { isTotalHealth });
  const purchasedFor = injectSubscriptionName(
    COPYTEXT[serviceHash][genotypeHash]['PURCHASED_FOR'],
    params,
  );

  return (
    <div className="spc-subs-receipt-wrapper">
      <Header logoUrl={logoUrl} />
      <div className="spc-subs-receipt" data-stor-id="receipt-order-summary-header">
        <div
          className={`spc-subs-receipt-container${storeState.hasSurveyResults ? '-nosurvey' : ''}`}
        >
          <div className="spc-subs-receipt-main">
            <table>
              <tbody>
                <tr>
                  <td className="spc-subs-receipt-header-table-td">
                    <div className="spc-subs-receipt-header-wrapper">
                      <h1
                        className="spc-subs-receipt-header"
                        data-stor-id="spc-subs-receipt-header"
                      >
                        {(!isSubBundle || isGenotyped) && <div>Yay DNA!</div>}
                        {header1}
                        {isUpgradeBundle && (
                          <>
                            <br />
                            {header2}
                          </>
                        )}
                      </h1>
                      <p className="spc-subs-receipt-email-notice">
                        {subheader}
                        &nbsp;
                        <span className="spc-subs-receipt-email">{accountEmail}</span>.
                      </p>
                      <div>
                        <button
                          className="spc-button-link spc-subs-receipt-button"
                          onClick={() => {
                            const event = isSubBundle
                              ? EVENTS_TO_MOBILE.STORE_HEALTH_SUBSCRIPTION_TRANSACTION_COMPLETE
                              : EVENTS_TO_MOBILE.STORE_SUBSCRIPTION_TRANSACTION_COMPLETE;
                            sendToNative(event);
                            window.location.href = ctaUrl;
                          }}
                        >
                          {cta}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table
                      className={`spc-subs-receipt-table${activatedAt ? ' mod-subs-info' : ''}`}
                    >
                      <tbody>
                        <tr>
                          <th className="spc-subs-receipt-table-title" colSpan={2}>
                            <div>
                              Order Summary
                              <span>
                                <button
                                  onClick={() => window.print()}
                                  className="spc-button-link spc-subs-receipt-print"
                                >
                                  Print Receipt
                                </button>
                              </span>
                            </div>
                          </th>
                        </tr>
                        <tr className="print-header">
                          <th colSpan={2} className="spc-subs-receipt-table-title">
                            Your 23andMe Receipt
                          </th>
                        </tr>
                        <tr className="spc-subs-receipt-table-row">
                          <td className="valign-top">{isSubBundle ? 'Items' : 'Item'}</td>
                          <td className="valign-top">
                            {isSubBundle && storeState.subBundleInfo.itemName}
                            {isSubBundle && (
                              <span>
                                <br />
                                <br />
                              </span>
                            )}
                            <SubscriptionName useServiceMark={true} {...{ isTotalHealth }} />
                            &nbsp;Membership
                          </td>
                        </tr>
                        <tr className="spc-subs-receipt-table-row">
                          <td className="valign-top no-right-padding extra-bottom-padding">
                            {purchasedFor}
                          </td>
                          <td className="valign-top">
                            {storeState.account.firstName} {storeState.account.lastName}
                            {!isGenotyped && (
                              <div className="spc-subs-receipt-start-notice">
                                <br />
                                Your <SubscriptionName />
                                &nbsp;membership will start when you receive your reports.
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr className="spc-subs-receipt-table-row">
                          <td className="valign-top no-right-padding">Order number</td>
                          <td className="valign-top">
                            {isUpgradeBundle && `Upgrade: ${storeState.subBundleInfo.receiptId}`}
                            {isSubBundle && (
                              <>
                                <br />
                                <br />
                                <SubscriptionName />:{' '}
                              </>
                            )}
                            {storeState.subscriptionInfo.receiptId}
                          </td>
                        </tr>
                        <tr className="spc-subs-receipt-table-row">
                          <td className="valign-top no-right-padding">Order date</td>
                          <td>{storeState.subscriptionInfo.createdAt}</td>
                        </tr>
                        <tr className="spc-subs-receipt-table-row mod-price">
                          <td className="valign-top no-right-padding">Price</td>
                          <td className="valign-top mod-billing-info">
                            {isSubBundle && (
                              <>
                                {storeState.subBundleInfo.itemName}:{' '}
                                {storeState.subBundleInfo.discountedPrice
                                  ? storeState.subBundleInfo.discountedPrice
                                  : storeState.subBundleInfo.price}
                                <br />
                                <br />
                              </>
                            )}
                            <SubscriptionPricing subscriptionInfo={storeState.subscriptionInfo} />
                            <br />
                            <br />
                            <SubscriptionRenewalTerms
                              subscriptionInfo={storeState.subscriptionInfo}
                              isDelayedBilling={!storeState.subBundleInfo.isGenotyped}
                              className="spc-subs-receipt-billing"
                              showCancelTerms={true}
                            />
                          </td>
                        </tr>
                        {hasValidAddress && hasValidName && (
                          <tr className="spc-subs-receipt-table-row payment-header">
                            <th className="billing-info" colSpan={2}>
                              Billing Information
                            </th>
                          </tr>
                        )}
                        {hasValidName && (
                          <tr className="spc-subs-receipt-table-row">
                            <td className="valign-top no-right-padding">Name</td>
                            <td className="valign-top">
                              {storeState.billingInfo.firstName} {storeState.billingInfo.lastName}
                            </td>
                          </tr>
                        )}
                        {hasValidAddress && (
                          <tr className="spc-subs-receipt-table-row">
                            <td className="valign-top no-right-padding">Address</td>
                            <td className="valign-top">
                              {storeState.billingInfo.lineOne}
                              <br />
                              {storeState.billingInfo.lineTwo}
                              {storeState.billingInfo.lineTwo ? <br /> : null}
                              {storeState.billingInfo.city} {storeState.billingInfo.state}{' '}
                              {storeState.billingInfo.zip}
                              <br />
                              {storeState.billingInfo.country}
                            </td>
                          </tr>
                        )}
                        {hasValidPaymentInfo && (
                          <tr className="spc-subs-receipt-table-row">
                            <td className="valign-top no-right-padding">Payment Information</td>
                            <td className="valign-top">
                              {storeState.billingInfo.cardType} ******{' '}
                              {storeState.billingInfo.lastFour}
                              <br />
                              {storeState.billingInfo.expirationMonth} /{' '}
                              {storeState.billingInfo.expirationYear}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="spc-subs-receipt-print-tos">
              Manage your membership, including payment information and auto-renewal cancellation,
              in your account settings: https://you.23andme.com/user/
              <br />
              See Membership Terms: {URLS.SUBSCRIPTION_TERMS_OF_SERVICE}
            </div>
          </div>
          <ul className="spc-subs-receipt-manage-subscription">
            <li className="mod-first">
              <div>
                <img src={iconPlusCloud} alt="manage your subscription" width="66" height="66" />
              </div>
              <div className="manage-text">
                Manage your membership
                <p>
                  You can manage your membership and payment settings, see your renewal date, or
                  cancel auto-renewal at any time.
                </p>
                <p>
                  <a href={`${URLS.YOUDOT}user/`}>Go to settings</a>
                </p>
              </div>
            </li>
          </ul>
        </div>
        {!storeState.hasSurveyResults && <Survey receiptId={receiptId} />}
      </div>
      <Footer />
    </div>
  );
}
