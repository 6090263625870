import React, { useEffect } from 'react';
import migraine from '../../images/migraine.png';
import drug from '../../images/drug.png';
import health from '../../images/health.png';
import relatives from '../../images/relatives.png';
import iconmoretolearn from '../../images/iconmoretolearn.png';
import historicalmatches from '../../images/historicalmatches.png';
import records from '../../images/records.png';
import SubscriptionName from './SubscriptionName';
import SubscriptionInfoValueProp from './SubscriptionInfoValueProp';
import { useStore } from '../../utils/storeUtils';
import dataLayer from '../../utils/dataLayer';

const SubscriptionInfo = () => {
  const [storeState, _] = useStore();
  const { region } = storeState;

  useEffect(() => {
    if (storeState.showRecordsArchives) dataLayer.trackFamilyRecordsSubscriptionInfoPageView();
  }, []);

  const valuePropsList = (
    <ul className="spc-subs-info-ul">
      {storeState.showRecordsArchives && (
        <SubscriptionInfoValueProp
          title="Records & Archives"
          description="Explore historical records about your ancestors and 
              choose information to add directly to your family tree. "
          icon={records}
          iconLabel="historical records and archives"
          region={region}
        />
      )}
      <SubscriptionInfoValueProp
        title="Premium Heart Health reports"
        description="Explore how your DNA can impact your heart health,
            and learn about lifestyle factors that can guide you toward proactive steps."
        icon={health}
        isResearchPowered={true}
        iconLabel="premium heart health reports"
      />
      {storeState.showHistoricalMatches && (
        <SubscriptionInfoValueProp
          title="Historical Matches"
          description="Uncover the DNA you share with people who lived hundreds or thousands of years ago.
              More historical profiles added throughout the year."
          icon={historicalmatches}
          iconLabel="historical matches"
          region={region}
        />
      )}
      <SubscriptionInfoValueProp
        title="Migraine and other premium health reports"
        description="Discover your genetic likelihood of getting migraine headaches,
            and get more DNA insights so that you can have a more informed discussion with your healthcare provider."
        icon={migraine}
        isResearchPowered={true}
        iconLabel="migraine and other premium health reports"
      />
      <SubscriptionInfoValueProp
        title="Pharmacogenetics reports*"
        description="Discover how your DNA can impact how your body processes certain medications, and feel
            empowered to discuss your reports with your healthcare provider."
        icon={drug}
        hasItiLink={true}
        iconLabel="drug response insights"
      />
      <SubscriptionInfoValueProp
        title="Advanced DNA Relatives features"
        description="Gain access to up to 5,000 DNA relatives – more than three times what you get in the
            Health + Ancestry Service."
        icon={relatives}
        iconLabel="dna relatives features"
      />
      <SubscriptionInfoValueProp
        title="More exclusive reports and enhanced features over time"
        description="New health reports and features will be delivered to members annually
            from January to December."
        icon={iconmoretolearn}
        iconLabel="more exclusive reports and enhanced features over time"
      />
    </ul>
  );

  return (
    <div className="spc-subs-info">
      <div className="spc-subs-info-title">
        <h1 className="spc-subs-info-h1" data-mdv-id="subscription-payment-header">
          Get our membership
        </h1>
        <p className="spc-subs-info-subtitle">
          Here’s what’s included with a&nbsp;
          <br className="spc-subs-info-subtitle-br" />
          <SubscriptionName useServiceMark={true} iconSize={10} /> membership:
        </p>
      </div>
      <div className="spc-subs-info-desktop">{valuePropsList}</div>
    </div>
  );
};

export default SubscriptionInfo;
